import { useHistory, useParams } from 'react-router-dom'

import BodySection from 'src/views/freightbooking/mybooking/review/BodySection'
import React from 'react'
import ViewBookingDetails from './ViewBookingDetails'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm }
} = components

function ViewBookingDetailsPage(props) {
  const { id } = useParams()
  const history = useHistory()
  const {showNotification} = props
  
  function onSubmit(data) {
    console.log('submit edit')
  }

  return (

    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <BodySection.FormFields
          disabled={false}
          helpdesk
          {...props}
        />
      }
      formikProps={{
        ...BodySection.formikProps,
        onSubmit: onSubmit
      }}

    />

  )
}

export default ViewBookingDetailsPage
