import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
//import SearchSIPage from './SearchSiPage'
import TablePage from './TablePage'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Helpdesk'
//import CreateSIViewSIDraft from 'src/views/shippinginstruction/createsi/viewsidraft/CreateSIViewSIDraftPage'
//import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
//import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ShippingInstruction'

function WrappedTablePage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
      <CngBasePage
        renderPage={(showSnackbar) => (
          <TablePage showNotification={showSnackbar} {...props} />
        )}
        extraCngBreadcrumbNameMap={breadcrumbNameMap}
      />
    )
}

export {
    WrappedTablePage as TablePage
}