import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Utils from 'src/views/freightbooking/shared/Utils'
import pathMap from 'src/paths/PathMap_Helpdesk'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import { components, constants, useServices } from 'cng-web-lib'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ViewBookingButtonComponent from '../viewbooking/ViewBookingButtonComponent'

const {
    button: { CngPrimaryButton, CngSecondaryButton },
    table: { useDefaultNotification }
} = components

function HelpdeskBookingActionMenu(props) {

    const history = useHistory()

    const { booking, bookingStatus, page, refreshAction} = props
    const bookStatus = bookingStatus || booking.bookingStatus || booking.bookStatus || ''

    let actions = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).actions : []
    let actionsH = bookStatus != '' ? Utils.getBookingStatusById(bookStatus).actionsH : []

    const translatedTextsObject = FreightBookingTranslationText()
    const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
    const { createRecord } = useServices()
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [confirmAction, setConfirmAction] = useState("")
    const CANCEL_ACTION = "cancel"
    const ARCHIVE_ACTION = "archive"
    const DELETE_ACTION = "delete"
    const HELPDESK_AMEND_BOOKING_STATUS_ACTION = "helpdesk amend booking status"

    const { showNotification } = props
    //console.log(showNotification)
    const {
        success: showSuccessNotification,
        error: showErrorNotification
    } = useDefaultNotification(showNotification)

    const showPrimary = false


    const gotoMyBooking = () => {
        history.push({
            pathname: '/cal-freightbooking/my-booking',
        })
    }

    const gotoViewBooking = () => {
        history.push({
            pathname: pathMap.FRB
        })
    }

    const deleteBooking = () => {
        console.log("delete NEW")
        setConfirmDialogOpen(true)
        setConfirmAction(DELETE_ACTION)
    }


    const proceedDeleteBooking = () => {
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            console.log(response)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)
            } else {

                showSuccessNotification(`${translatedTextsObject.deleteSuccess} `)
                redirectToMyBooking()
            }
        }

        createRecord.execute(
            FreightBookingApiUrls.DELETE_BOOKING,
            { bookingId: props.booking.bookingId },
            onSuccess,
            onError
        )

    }

    const viewSI = () => {
        console.log("viewSI clicked")
    }

    const createSI = () => {
        console.log("createSI clicked")
    }

    const trackContainers = () => {
        console.log("trackContainers clicked")
    }

    const amendBooking = (prevPage) => event => {
        history.push({
            pathname: pathMap.MY_BOOKING_AMEND,
            state: { booking: props.booking, prevPage: prevPage, action: Utils.Action.amend }
        })
    }

    const cancelBooking = () => {
        console.log("new cancel")
        setConfirmDialogOpen(true)
        setConfirmAction(CANCEL_ACTION)
    }

    const helpdeskAmendBookingStatus = () => {
        console.log("Helpdesk Amend Booking Status")
        setConfirmDialogOpen(true)
        setConfirmAction(HELPDESK_AMEND_BOOKING_STATUS_ACTION)
    }

    const confirmDialog = () => {

        console.log("confirmAction--> " + confirmAction)
        setConfirmDialogOpen(false)//close the dialog first
        switch (confirmAction) {
            case CANCEL_ACTION:
                proceedCancelBooking()
                break

            case ARCHIVE_ACTION:
                proceedArchiveBooking()
                break

            case DELETE_ACTION:
                proceedDeleteBooking()
                break

            case HELPDESK_AMEND_BOOKING_STATUS_ACTION:
                proceedHelpdeskAmendBookingStatus()
                break

        }




    }

    function redirectToMyBooking() {


        if (page === Utils.Page.BookingDetail) {
            gotoViewBooking()
        }
        else {
            //if action is done from menu list, then just refresh the list
            refreshAction()
        }
    }

    function redirectToViewBooking() {
        gotoViewBooking()
    }

    function onError(error) {
        console.log('connection-request error', error.message)
        showErrorNotification(error.message)
    }

    const proceedCancelBooking = () => {
        console.log("cancel")
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            console.log(response)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)
            } else {

                showSuccessNotification(`${translatedTextsObject.cancelSuccess} `)
                redirectToMyBooking()
            }
        }

        createRecord.execute(
            FreightBookingApiUrls.CANCEL_BOOKING,
            { bookingId: props.booking.bookingId },
            onSuccess,
            onError
        )
    }

    const archiveBooking = () => {
        console.log("archive NEW")
        setConfirmDialogOpen(true)
        setConfirmAction("archive")
    }





    const proceedArchiveBooking = () => {
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            console.log(response)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)
            } else {

                showSuccessNotification(`${translatedTextsObject.archivedSuccess} `)
                redirectToMyBooking()
            }
        }




        createRecord.execute(
            FreightBookingApiUrls.ARCHIVE_BOOKING,
            { bookingId: props.booking.bookingId },
            onSuccess,
            onError
        )
    }

    const editBooking = (prevPage) => event => {

        history.push({
            pathname: pathMap.CREATE_NEW_BOOKING,
            state: { booking: props.booking, prevPage: prevPage, action: Utils.Action.edit }
        })

    }


    const copyBooking = () => {
        setConfirmDialogOpen(false)//close the dialog first

        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            // setLoading(false)
            console.log(response)
            if (response.errorMessages != null) {

                showErrorNotification(response.errorMessages)
            } else {

                showSuccessNotification(`${translatedTextsObject.copySuccess} ${response.freightBookingId}`)
                history.push({
                    pathname: pathMap.CREATE_NEW_BOOKING,
                    state: { booking: response, action: Utils.Action.copy }
                })
            }
        }


        createRecord.execute(
            FreightBookingApiUrls.COPY_BOOKING,
            { bookingId: props.booking.bookingId },
            onSuccess,
            onError
        )

    }

    const proceedHelpdeskAmendBookingStatus = () => {
        function onSuccess(response) {
            console.log('connection-request onSuccess', response)
            console.log(response)
            if (response.errorMessages != null) {
              showErrorNotification(response.errorMessages)
            } else {
              showSuccessNotification(`${translatedTextsObject.helpdeskAmendBookingStatusSuccess} `)
              redirectToViewBooking()
            }
        }

        createRecord.execute(
            FreightBookingApiUrls.HELPDESK_AMEND_BOOKING_STATUS,
            { bookingId: props.booking.bookingId },
            onSuccess,
            onError
        )
    }

    const eventHandlers = {
        gotoMyBooking,
        copyBooking,
        archiveBooking,
        editBooking,
        amendBooking,
        deleteBooking,
        cancelBooking,
        viewSI,
        createSI,
        trackContainers,
        gotoViewBooking,
        helpdeskAmendBookingStatus
    }



    return (
        <div>
            {
                page
                 === Utils.Page.BookingDetail && <ViewBookingButtonComponent actions={actionsH} booking={props.booking}
                    eventHandlers={eventHandlers} />
            }

            <ConfirmDialog
                isConfirmDialogOpen={confirmDialogOpen}
                closeDialog={() => setConfirmDialogOpen(false)}
                confirmDialog={confirmDialog}
                content={translatedTextsObject.dialogChangeStatusGeneralContent}
                okMsg={uiTranslatedTextsObject.ok}
                cancelMsg={uiTranslatedTextsObject.cancel}
                title={translatedTextsObject.dialogCancelBookingTitle}
            />
        </div>
    )
}


export default HelpdeskBookingActionMenu