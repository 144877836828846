import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import ViewBookingDetailsPage from './ViewBookingDetailsPage'
import ViewBookingPage from './ViewBookingPage'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Helpdesk'

// import MyBookingReviewDraftPage from './review/MyBookingReviewDraftPage'
// import MyBookingAmendPage from './amend/MyBookingAmendPage'
// import MyBookingAmendReviewPage from './amend/MyBookingAmendReviewPage'


function WrappedViewBookingPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            renderPage={(showSnackbar) => (
                <ViewBookingPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


function WrappedViewBookingDetailsPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            renderPage={(showSnackbar) => (
                <ViewBookingDetailsPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


export {
    WrappedViewBookingPage as ViewBookingPage,
    WrappedViewBookingDetailsPage as ViewBookingDetailsPage
}