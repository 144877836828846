import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import ViewSIPage from './ViewSIPage'
import ViewSIDetailsPage from './ViewSIDetailsPage'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Helpdesk'

function WrappedViewSIPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            renderPage={(showSnackbar) => (
                <ViewSIPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}

function WrappedViewSIDetailsPage(props) {
    const breadcrumbNameMap = useBreadcrumbNameMap()
    return (
        <CngBasePage
            renderPage={(showSnackbar) => (
                <ViewSIDetailsPage showNotification={showSnackbar} {...props} />
            )}
            extraCngBreadcrumbNameMap={breadcrumbNameMap}
        />
    )
}


export {
    WrappedViewSIPage as ViewSIPage,
    WrappedViewSIDetailsPage as ViewSIDetailsPage,
}