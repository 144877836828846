import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    Chip,
    Grid,
    Paper,
    Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import AuditTrailTableComponent from 'src/components/auditTrail/AuditTrailTableComponent'
import BookingActionMenu from 'src/views/freightbooking/components/BookingActionMenu'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import HeaderBarTop from 'src/views/freightbooking/components/HeaderBarTop'
import ReviewBookingButtonComponent from 'src/views/freightbooking/components/ReviewBookingButtonComponent'
import Utils from 'src/views/freightbooking/shared/Utils'
import ViewBookingTypeComponent from 'src/views/freightbooking/components/ViewBookingTypeComponent'
import ViewCargoComponent from 'src/views/freightbooking/components/ViewCargoComponent'
import ViewContactComponent from 'src/views/freightbooking/components/ViewContactComponent'
import ViewContainerComponent from 'src/views/freightbooking/components/ViewContainerComponent'
import ViewDgCargoComponent from 'src/views/freightbooking/components/ViewDgCargoComponent'
import ViewDocumentComponent from 'src/views/freightbooking/components/ViewDocumentComponent'
import ViewLastModifiedComponent from 'src/views/freightbooking/components/ViewLastModifiedComponent'
import ViewPaymentComponent from 'src/views/freightbooking/components/ViewPaymentComponent'
import ViewScheduleComponent from 'src/views/freightbooking/components/ViewScheduleComponent'
import ViewShipmentComponent from 'src/views/freightbooking/components/ViewShipmentComponent'

const {
    CngGridItem
} = components


function FormFields(props) {
    const { booking, ports, auditList} = props
    const translatedTextsObject = FreightBookingTranslationText()

    const containers = booking.bookingContainers || []
    const cargos = booking.bookingCargoes || []
    const dgCargos = booking.bookingDGCargoes || []
    const documents = booking.bookingDocuments || []
    const hazardous = booking.hazardous || false
    const location = useLocation()
    // console.log(props)
    const showNotification = props.showNotification
    console.log(location.state)
    console.log(booking)
    let buttonLayout = {
        showEdit: true,
        showCopy: true,
        showDelete: true,
        showCancel: false,
        showArchive: false

    }
    return (

        <Box>
            <Paper>
                <HeaderBarTop booking={booking}></HeaderBarTop>
                <Box p={5} borderTop={1} borderBottom={1} className="border-light-blue" >
                    <Box pt={5}>
                        <ViewLastModifiedComponent date={booking.createdDate}
                            text={translatedTextsObject.bookingCreatedOn} />
                    </Box>

                    {booking.shipmentType &&
                        <Box pt={5}>
                            <ViewBookingTypeComponent.FormBody bookingTypeData={booking} />
                        </Box>
                    }

                    {(booking.polCode == null || booking.podCode == null) ?
                        (

                            <Box></Box>

                        )
                        : (<Box pt={5}>
                            <ViewScheduleComponent.FormBody scheduleData={booking} />
                        </Box>)}


                    {(booking.moveType == null || booking.placeOfDeliveryCode == null || booking.placeOfReceiptCode == null) ?
                        (

                            <Box></Box>

                        )
                        : (<Box pt={5}>
                            <ViewShipmentComponent.FormBody shipmentData={booking} />
                        </Box>)}



                    <Box pt={5}>
                        <ViewContainerComponent.FormBody containers={containers} />
                    </Box>

                    {
                        hazardous ? (
                            <Box pt={5}>

                                <ViewDgCargoComponent.FormBody cargo={dgCargos} showDetails />
                            </Box>
                        ) : (
                            <Box pt={5}>
                                <ViewCargoComponent.FormBody cargo={cargos} />
                            </Box>
                        )
                    }
                    {
                        documents.length > 0 && (
                            <Box pt={5}>
                                <ViewDocumentComponent.FormBody document={documents} />
                            </Box>
                        )}
                    <Box pt={5}>
                        <AuditTrailTableComponent.FormBody booking={booking} ports={ports} auditList={auditList}/>
                    </Box>
                    <Box alignItems='center'>
                        <BookingActionMenu booking={booking}
                            showNotification={showNotification}
                            page={Utils.Page.BookingDetail}
                            {...props} />
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

const MyBookingReviewDraft = Object.freeze({
    // formikProps: FORMIK_PROPS,
    FormFields: FormFields
    // toClientDataFormat: toClientDataFormat,
    // toServerDataFormat: toServerDataFormat
})


export default MyBookingReviewDraft