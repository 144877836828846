import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Grid,
    CardContent,
    Button
} from '@material-ui/core'
import React, { useState } from 'react'
// import SearchIcon from '@material-ui/icons/Search'
// import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
// import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import { components } from 'cng-web-lib'

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngSelectField
        },
    },
    CngGridItem,
    button: { CngPrimaryButton },

} = components



const FormBody = (props) => {

    const translatedTextsObject = FreightBookingTranslationText()
    const [bookingTypeExpanded, setBookingTypeExpanded] = useState(false)
    const [shipmentTypeField, , { setValue: setShipmentTypeField }] = useField('shipmentType')

    const handleBookingExpand = () => {
        setBookingTypeExpanded(!bookingTypeExpanded);
    };

    let pendingShown = props.pendingClicked ? 'shown(' + props.shownCount + ')' : ''
    let processedShown = !props.pendingClicked ? 'shown(' + props.shownCount + ')' : ''
    return (

        <Card>
            <CardContent>

                <Grid container spacing={1}>
                    <CngGridItem item xs={9} sm={9}>
                        <Grid container spacing={1}>

                            {/* <CngGridItem item xs={3} sm={3}>

                                <Button
                                    onClick={props.clickPending}
                                    variant={props.pendingClicked ? "outlined" : "text"}>
                                    NewPending({props.pendingCount})
                                </Button>
                            </CngGridItem>

                            <CngGridItem item xs={3} sm={3}>
                                <Button
                                    onClick={props.clickProcessed}
                                    variant={!props.pendingClicked ? "outlined" : "text"} >
                                    NewProcessed({props.processedCount})
                                </Button>
                            </CngGridItem>
                            <CngGridItem item xs={6} sm={6}>

                            </CngGridItem>
                            <CngGridItem item xs={3} sm={3} style={{ marginLeft: 10 }}>
                                <div>NEWSHOWN({props.shownCount})</div>

                            </CngGridItem> */}
                            <CngGridItem> 
                                <div>
                                    Showing {props.shownCount} out of {props.processedCount + props.pendingCount} results
                                </div>
                            </CngGridItem>
                        
                        </Grid>
                    </CngGridItem>
                    <CngGridItem item xs={3} sm={3}>
                        <CngSelectField
                            name="sortCode"
                            label="Sort By"
                            items={props.sortByList}
                            onClick={props.handleSortByChange}
                            inlineLabel
                        />


                    </CngGridItem>
                </Grid>

            </CardContent>
        </Card >
    )
}

const CountAndSortComponent = Object.freeze({
    FormBody: FormBody,

})

export default CountAndSortComponent
