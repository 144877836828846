import React from 'react'
import { useHistory } from 'react-router-dom'

import ViewSIFormProperties from './ViewSIFormProperties';
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_Helpdesk'

const {
  form: { CngViewForm }
} = components

function ViewSIPage({ showNotification }) {
  const history = useHistory()


  function onSubmit(data) {
    console.log('submit', data)


    history.push('/');
    history.replace({ pathname: pathMap.VIEW_SI, state: data })
  }

  return (

    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ViewSIFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...ViewSIFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    />

  )
}

export default ViewSIPage
