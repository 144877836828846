import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
    Card,
    CardContent,
    Fab,
    Grid,
    Toolbar
} from '@material-ui/core'
import { Box, Paper } from '@material-ui/core'
import { Yup, components, useServices } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'

import Api from 'src/views/freightbooking/shared/api'
import ApiParser from 'src/views/freightbooking/shared/apiParser'
import BookingDetailComponent from 'src/views/freightbooking/components/BookingDetailComponent'
import BookingListComponent from 'src/views/freightbooking/components/BookingListComponent'
import BookingListNoResult from 'src/views/freightbooking/components/BookingListNoResult'
import ButtonTabComponent from 'src/views/freightbooking/components/ButtonTabComponent'
import CountAndSortComponenet from '../components/CountAndSortComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import FilterPanelComponent from 'src/views/freightbooking/components/FilterPanelComponent'
import FreeTextSearchComponent from 'src/views/freightbooking/components/FreeTextSearchComponent'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { LensTwoTone } from '@material-ui/icons'
import { Paperclip } from 'react-feather'
import ResetFilterComponent from 'src/views/freightbooking/components/ResetFilterComponent'
import ScrollTop from 'src/views/freightbooking/components/ScrollTop'
import Utils from 'src/views/common/utils/Utils'
import makeValidationSchema from 'src/views/freightbooking/shared/MakeValidationSchema'
import searchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { populatePortDesc } from '../../common/ui/PopulatePortDescription'

const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngDateField,
        },
    },
    CngGridItem,gt
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    dateRange: 'all',
    pendingClicked: true,
    filterText: '', 
    sortCode: '',
    pendingStatusCount: 0,
    processedStatusCount: 0,
    statusCodes: [CalistaConstant.BOOKING_STATUS_DRAFT, CalistaConstant.BOOKING_STATUS_AMENDED, CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL,
                    CalistaConstant.BOOKING_STATUS_APPROVED, CalistaConstant.BOOKING_STATUS_CANCELLED, CalistaConstant.BOOKING_STATUS_REJECTED],
    status: {},
    partyIds: [],
    carriers: {},
    showArchived: true,
    statusTabChanged: true,
})

function DEFAULT_VALIDATION_SCHEMA(translate) {

    return Yup.object({})
}

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
    //makeValidationSchema: makeValidationSchema
}

function FormFields({ disabled, showNotification, shouldHideMap }) {
    //move this from BookingTypeComponent to share with Cargo component
    const { fetchRecords } = useServices()
    // const location = useLocation()
    // const { submitForm } = useFormikContext()
    const [statusCodesField, , { setValue: setStatusCodesField }] = useField('statusCodes')
    const [dateRangeField, , { setValue: setDateRangeField }] = useField('dateRange')
    const [statusField, , { setValue: setStatusField }] = useField('status')
    const [sortCodeField, , { setValue: setSortCodeField }] = useField('sortCode')
    const [, , { setValue: setSortByListField }] = useField('sortByList')
    const [pendingClickedField, , { setValue: setPendingClickedField }] = useField('pendingClicked')
    const [filterTextField, , { setValue: setFilterTextField }] = useField('filterText')
    const [, , { setValue: setPendingStatusCountField }] = useField('pendingStatusCount')
    const [, , { setValue: setProcessedStatusCountField }] = useField('processedStatusCount')
    const [carriersField, , { setValue: setCarriersField }] = useField('carriers')
    const [showArchivedField, , { setValue: setShowArchivedField }] = useField('showArchived')
    const [partyIdsField, , { setValue: setPartyIdsField }] = useField('partyIds')
    const [statusTabChangedField, , { setValue: setStatusTabChangedField }] = useField('statusTabChanged')

    const pendingStatusCode = [CalistaConstant.BOOKING_STATUS_DRAFT, CalistaConstant.BOOKING_STATUS_AMENDED, CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL] //should be loaded from api
    const processedStatusCode = [CalistaConstant.BOOKING_STATUS_APPROVED, CalistaConstant.BOOKING_STATUS_CANCELLED, CalistaConstant.BOOKING_STATUS_REJECTED]
    const defaultDateRange = "all" //default value to query via API
    const VALUE_ALL = "ALL"
    const defaultShowArchived = true


    const [dateRange, setDateRange] = useState()
    const [sortByList, setSortByList] = useState()
    const [filterText, setFilterText] = useState()
    const [bookingList, setBookingList] = useState([])
    //const [originalBookingList, setOriginalBookingList] = useState([])
    const [showArchived, setShowArchived] = useState()
    const [loading, setLoading] = useState(true)
    const [shownCount, setShownCount] = useState(0)

    const clickPending = () => {
        console.log("clickPending")
        setPendingClicked(true)
        setPendingClickedField(true)
        setStatusCodesField(pendingStatusCode)
        setStatus(pendingStatusList)
        setStatusField(pendingStatusList)
        setStatusTabChangedField(true)
        //submitForm()
        manualRefresh()
    }

    const clickProcessed = () => {
        console.log("click processed")
        setPendingClicked(false)
        setPendingClickedField(false)
        setStatusCodesField(processedStatusCode)
        setStatus(processedStatusList)
        setStatusField(processedStatusList)
        setStatusTabChangedField(true)
        //submitForm()
        manualRefresh()
    }

    const handleSortByChange = (e) => {
        console.log("handleSortByChange")
        //alert(e.target.value)
        if (e.target.value != null && e.target.value != 0) {
            setSortCodeField(e.target.value)
            setStatusTabChangedField(false)

            // submitForm()
            manualRefresh()
        }


    }

    const handleClearFiltreAndTextButtonClick = () => {
        console.log("handleClearFiltreAndTextButtonClick")

        setFilterText("")
        setFilterTextField("")
        handleClearAllButtonClick()

    }
    const handleClearAllButtonClick = () => {

        console.log("handleClearAllButtonClick")


        setDateRange(defaultDateRange)
        setDateRangeField(defaultDateRange)
        let newStatus = [...status]
        newStatus = newStatus.map((item) => ({
            ...item,
            checked: true
        }))

        console.log(newStatus)
        setStatus(newStatus)
        setStatusField(newStatus)

        let newCarriers = [...carriers]
        newCarriers = newCarriers.map((item) => ({
            ...item,
            checked: true
        }))

        setCarriers(newCarriers)
        setCarriersField(newCarriers)

        setShowArchivedField(defaultShowArchived)
        setShowArchived(defaultShowArchived)


        let newStatusCode = getNewStatuCode(newStatus)
        console.log(newStatusCode)

        setStatusCodesField(newStatusCode)


        setStatusTabChangedField(true) //force carrier list load from api
        // submitForm()
        manualRefresh()

    }


    const handleFilterTextChange = (e) => {

        console.log(e.target)
        setFilterText(e.target.value)
        setFilterTextField(e.target.value)

        // submitForm()

    };

    const handelFreeTextSearch = () => {
        setStatusTabChangedField(true)
        manualRefresh()
    }

    const onStatusCheckBoxChange = (index) => ({ target: { checked } }) => {

        console.log("handleStatusCheckBoxChange")
        let newStatus = [...status]
        const currentStatus = status[index]
        if (currentStatus.statusDesc !== VALUE_ALL) {
            const allStatus = status[status.length - 1]

            newStatus[index] = { ...currentStatus, checked: !currentStatus.checked }
            newStatus[status.length - 1] = { ...allStatus, checked: false }
        } else {
            newStatus = newStatus.map((item) => ({
                ...item,
                checked: !currentStatus.checked
            }))
        }
        // Status is the display
        console.log(newStatus)
        setStatus(newStatus)
        setStatusField(newStatus)
        console.log(newStatus)

        //get new status code and list
        let newStatusCode = getNewStatuCode(newStatus)
        console.log(newStatusCode)

        setStatusCodesField(newStatusCode)
        setStatusTabChangedField(true) //force carrier list load from api
        //submitForm()
        manualRefresh()

    }

    function getNewStatuCode(newStatus) {
        let temp = []
        newStatus.filter((v) => v.checked == true)
            .map((v) => (
                // console.log(v.statusCode)
                // let temp2 = v.statusCode
                temp.push(v.statusCode)
            ))


        return temp
    }

    const showArchivedChange = (e) => {
        console.log("showArchivedChange")
        console.log(e.target.checked)
        setShowArchivedField(e.target.checked)
        setStatusTabChangedField(true) //force carrier list load from api
        // submitForm()
        manualRefresh()
    }

    const dateRangeChange = (e) => {

        console.log(e.target.defaultValue)
        setStatusTabChangedField(true)
        setDateRange(e.target.defaultValue)
        setDateRangeField(e.target.defaultValue)

        manualRefresh()
    }

    const onCarrierCheckBoxChange = (index) => ({ target: { checked } }) => {
        console.log("onCarrierCheckBoxChange " + index)
        let newCarriers = [...carriers]
        const carrier = carriers[index]
        console.log(carrier.partyId)
        if (carrier.nameOth !== VALUE_ALL) {
            const allCarrier = carriers[carriers.length - 1]

            newCarriers[index] = { ...carrier, checked: !carrier.checked }
            newCarriers[carriers.length - 1] = { ...allCarrier, checked: false }
        } else {
            newCarriers = newCarriers.map((item) => ({
                ...item,
                checked: !carrier.checked
            }))
        }

        setCarriers(newCarriers)
        setCarriersField(newCarriers)

        let temp = []
        newCarriers.filter((v) => v.checked == true)
            .map((v) => (
                // console.log(v)
                // let temp2 = v.partyId
                temp.push(v.partyId)
            ))

        console.log(temp)

        setPartyIdsField(temp)
        setStatusTabChangedField(false)

        // submitForm()
        manualRefresh()
    }

    // const performFilter = (newCarriers) => {
    //     console.log("performFilter")
    //     console.log(originalBookingList)
    //     const newBookingList = [...originalBookingList]

    //     setBookingList(newBookingList.filter((booking) => {
    //         let item =
    //             newCarriers &&
    //             newCarriers.find(
    //                 (newCarrier) =>
    //                     newCarrier.partyId === booking.carrierPartyId && newCarrier.checked
    //             )
    //         return item === undefined ? false : true
    //     })
    //     )

    // }

    let searchCriteria = {
        filterText: filterTextField.value,
        dateRange: dateRangeField.value,
        statusCodes: statusCodesField.value,
        showArchived: showArchivedField.value,
        partyIds: partyIdsField.value,
        sortCode: Utils.isEmptyString(sortCodeField.value) ? 1 : sortCodeField.value

    }


    const [carriers, setCarriers] = useState([])

    const [status, setStatus] = useState([])
    const [pendingStatusList, setPendingStatusList] = useState([])
    const [processedStatusList, setProcessedStatusList] = useState([])
    const [pendingCount, setPendingCount] = useState(0)
    const [processedCount, setProcessedCount] = useState(0)
    const [pendingClicked, setPendingClicked] = useState()
    const [loadingCriteria, setLoadingCriteria] = useState()
    const [refresh, setRefresh] = useState(false)
    const [portcodeToDescList, setPortcodeToDescList] = useState([])

    const manualRefresh = () => {
        setRefresh(!refresh)
        setLoading(true)
        setBookingList([])
        setShownCount(0)

    }

    useEffect(() => {

        console.log(statusCodesField)

        //setFilterText(Utils.isEmptyString(filterTextField.value) ? "" : filterTextField.value)
        //setFilterTextField(Utils.isEmptyString(filterTextField.vlaue) ? "" : filterTextField.vlaue)
        setPendingClicked(pendingClickedField.value)
        //setPendingClickedField(location.state == null ? true : location.state.pendingClicked)
        // setDateRange(location.state == null ? defaultDateRange : location.state.dateRange)

        setSortCodeField((sortCodeField.value == null || sortCodeField.value == "") ? 1 : sortCodeField.value)

        //setShowArchived(location.state == null ? defaultShowArchived : location.state.showArchived)
        // setShowArchived(showArchivedField.value == null ? defaultShowArchived : showArchivedField.value)
        // setShowArchivedField(showArchivedField.value == null ? defaultShowArchived : showArchivedField.value)
        if (sortCodeField.value == null || sortCodeField.value == "") {
            //fetch sort code when the page is first loaded, the result will remain static
            getSortingType()

        } else {
            // setPendingCount(location.state.pendingStatusCount)
            // setPendingStatusCountField(location.state.pendingStatusCount)
            // setProcessedCount(location.state.processedStatusCount)
            // setProcessedStatusCountField(location.state.processedStatusCount)
            // setStatus(location.state.status)
            // setStatusCodesField(location.state.statusCodes)
            // setStatusField(location.state.status)
            // setSortByList(sortCodeField.value)
            // setSortByListField(sortCodeField.value)



            // setShowArchived(showArchivedField.value)
            // setShowArchivedField(showArchivedField.value)
        }

        //get status code and count
        fetchHelpdeskStatusCodeAndCountFromAPI()

        //get party id based on status code, date range and sort code


    }, [refresh])

    function populatePartyId(dat) {
        let FromApi = ApiParser.parsePartyId(dat)
        if (FromApi[0] !== undefined) {
            let newRecord = { ...FromApi[0] }
            newRecord.nameOth = 'ALL'
            newRecord.bookingCount = FromApi.reduce((a, b) => a + b.bookingCount, 0)
            FromApi = [...FromApi, newRecord]

            console.log(FromApi)

            let partyIdsForSearch = getPartyIdForBookingList(dat.content)
            console.log(partyIdsForSearch)
            getHelpdeskBookingList()
        } else {
            setLoading(false)
            setPartyIdsField(Utils.isEmptyString(partyIdsField.value) ? [] : partyIdsField.value)
        }

        if (reloadCarrierCheckBoxNeeded()) {
            setCarriers(FromApi)
            setCarriersField(FromApi)
        }
        else {
            setCarriers(carriersField.value)
            //  setCarriersField(carriersField.value)
        }
    }
    function fetchHelpdeskPartyIdFromAPI() {
        Api.fetchHelpdeskPartyId(fetchRecords, searchCriteria, populatePartyId)
    }

    function reloadCarrierCheckBoxNeeded() {

        return statusTabChangedField.value
    }

    function getPartyIdForBookingList(carrierList) {

        console.log("getPartyIdForBookingList")
        let partyIdss = []
        if (reloadCarrierCheckBoxNeeded()) {
            console.log("loading party id from api")

            carrierList.map((v) => {
                console.log(v.partyId)
                partyIdss.push(v.partyId)
            })

            searchCriteria.partyIds = partyIdss
            setPartyIdsField(partyIdss)
        } else {
            console.log("loading party id from hisotry")
            setPartyIdsField(partyIdsField.value)
        }
        return partyIdss
    }

    function populateStatusCodeAndCount(dat) {
        const data = ApiParser.parseHelpdeskStatusCodeAndCount(dat)
        setPendingCount(data.pendingCount)
        setPendingStatusCountField(data.pendingCount)
        setProcessedStatusCountField(data.processedCount)
        setProcessedCount(data.processedCount)
        console.log(statusField.value)
        console.log(data)
        setStatus(Utils.isEmptyObject(statusField.value) ? data.fullList
             : statusField.value)
        //setStatusCodesField(location.state == null ? pendingStatusCode : location.state.statusCodes)
        //setStatusField(location.state == null ? data.pendingList : location.state.status)

        setPendingStatusList(data.pendingList)
        setProcessedStatusList(data.processedList)
        console.log(pendingClicked)
        //let statusCodes = pendingClicked ? pendingStatusCode : processedStatusCode
        fetchHelpdeskPartyIdFromAPI()
    }

    function fetchHelpdeskStatusCodeAndCountFromAPI() {
        Api.fetchHelpdeskStatusCodeAndCount(fetchRecords, populateStatusCodeAndCount)
    }

    function populateSortingType(dat) {
        const list = ApiParser.parseSortingType(dat)
        setSortByList(list)
        setSortByListField(list)
    }


    function getLeftCount() {

        // let count = (pendingClicked ? pendingCount - shownCount : processedCount - shownCount)
        let count = pendingCount + processedCount - shownCount
        return count
    }

    function showNoResultPage() {

        return (bookingList.length == 0 && getLeftCount() == 0 && !loading)
    }

    function getSortingType() {
        console.log("getSortingType")
        Api.fetchSortingType(fetchRecords, populateSortingType)
    }

    function populateBookingList(data) {
        // Trying to make BookingList have all content
        pendingClicked ? console.log("Pending") : console.log("Processed")
        console.log(data)
        setBookingList(data.content)
        // setOriginalBookingList(data.content)
        populatePortDesc(data.content, fetchRecords, setPortcodeToDescList)
        setLoading(false)
        setShownCount(data.totalElements)
    }

    useEffect(() => {
        let tempBookingList = [...bookingList]
        tempBookingList.forEach((obj) => {
            obj.placeOfDelivery = portcodeToDescList[obj.placeOfDeliveryCode] || obj.placeOfDelivery
            obj.placeOfReceipt = portcodeToDescList[obj.placeOfReceiptCode] || obj.placeOfReceipt
        })
        setBookingList(tempBookingList)
    }, [portcodeToDescList])

    function getHelpdeskBookingList() {
        Api.fetchHelpdeskBookingList(fetchRecords, searchCriteria, populateBookingList)
        setLoadingCriteria(searchCriteria)
    }


    // function getLeftCount() {
    //     let count = (pendingClicked ? pendingCount - shownCount : processedCount - shownCount)
    //     return count
    // }

    function showNoResultPage() {
        return (bookingList.length == 0 && getLeftCount() == 0 && !loading)
    }

    return (
        <Card className="page-content">
            <Box style={{ display: (showNoResultPage()) ? "inline" : "none" }}>
                <BookingListNoResult />
            </Box>
            <Box style={{ display: (showNoResultPage()) ? "none" : "inline" }}>
                <Toolbar id="back-to-top-anchor" />

    

                <Grid container xs={12} ms={12} spacing={2} justify="center">
                    <CngGridItem xs={9} ms={9} >
                        <Card>
                            <CardContent>
                                {/* <Grid container xs={12} ms={12} spacing={1}> */}

                                
                                <CngGridItem xs={12} ms={12} >
                                    <FreeTextSearchComponent.FormBody
                                        handleInputChange={handleFilterTextChange}
                                        filterText={filterTextField.value}
                                        handelFreeTextSearch={handelFreeTextSearch}
                                        helpdesk = {true}
                                    />
                                </CngGridItem>

                                <CngGridItem xs={12} ms={12} >
                                    <CountAndSortComponenet.FormBody
                                        pendingCount={pendingCount}
                                        processedCount={processedCount}
                                        shownCount={shownCount}
                                        pendingClicked={pendingClicked}
                                        clickPending={clickPending}
                                        clickProcessed={clickProcessed}
                                        sortByList={sortByList}
                                        handleSortByChange={handleSortByChange} />
                                </CngGridItem>

                                <CngGridItem xs={12} ms={12} >
                                    {/* <Box style={{ display: (showNoResultPage()) ? "none" : "inline" }}> */}
                                    <Box>
                                        <BookingListComponent.FormBody
                                            searchCriteria={loadingCriteria}
                                            bookingList={bookingList}
                                            showNotification={showNotification}
                                            handleClearAllButtonClick={handleClearFiltreAndTextButtonClick}
                                            leftCount={getLeftCount()}
                                            refreshAction={manualRefresh} 
                                            helpdesk={true}/>
                                    </Box>
                                </CngGridItem>

                            </CardContent>
                        </Card>
                        {/* </Grid> */}
                    </CngGridItem>

                    <CngGridItem sm={3} xs={12} >
                        <Grid container xs={12} ms={12} spacing={2}>
                            {/* <CngGridItem xs={12} ms={12} >
                                <FreeTextSearchComponent.FormBody
                                    handleInputChange={handleFilterTextChange}
                                    filterText={filterTextField.value}
                                    handelFreeTextSearch={handelFreeTextSearch}
                                />
                            </CngGridItem> */}
                            <CngGridItem xs={12} ms={12} >
                                <FilterPanelComponent
                                    handleClearAllButtonClick={handleClearAllButtonClick}
                                    onStatusCheckBoxChange={onStatusCheckBoxChange}
                                    showArchivedChange={showArchivedChange}
                                    onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                                    dateRange={dateRangeField.value}
                                    carriers={carriers}
                                    status={status}
                                    showArchived={showArchivedField.value}
                                    dateRangeChange={dateRangeChange}
                                    helpdesk
                                />
                            </CngGridItem>
                            <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                                <CngBackdrop loading={loading} />
                            </CngGridItem>

                        </Grid>
                    </CngGridItem>
                    <ScrollTop>
                        <Fab color="secondary" size="small" aria-label="scroll back to top">
                            <KeyboardArrowUpIcon />
                        </Fab>
                    </ScrollTop>
                </Grid>
            </Box>
        </Card>

    )
}

const ViewBookingFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    FormFields: FormFields
})


export default ViewBookingFormProperties