import {
  Box,
  Card,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core'
import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { faFileExcel, faFilePdf } from '@fortawesome/pro-light-svg-icons'

import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
import ExportPdf from 'src/views/vesselschedule/component/ExportPdf.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { faFileExport } from '@fortawesome/pro-light-svg-icons'
import { saveAs } from 'file-saver'
import { withStyles } from '@material-ui/core/styles'

const {
  table: { useDefaultNotification }
} = components

const toolbarClasses = makeStyles((theme) => ({
  buttonIcons: {
    '& .svg-inline--fa': { fontSize: 'inherit' }
  },
  menu: {
    '& .MuiMenu': {
      '&-paper': { borderRadius: 10 },
      '&-list': {
        maxWidth: '60vw',
        padding: 2,
        '& .MuiListItem-root:not(.Mui-disabled)': {
          letterSpacing: 0.21,
          fontSize: '.9em',
          padding: '8px 10px',
          minWidth: '12em',
          '&:hover': {
            backgroundColor:
              theme.palette.component.popoverMenu.listItemHoverFill
          }
        }
      }
    },
    '& .ng-mattbl-popover-checkbox': {
      padding: 0,
      paddingRight: theme.spacing(1)
    },
    '& .ng-mattbl-popover-title': {
      backgroundColor:
        theme.palette.component.textFormControl.dropdownMenuSelectedFill,
      borderRadius: 8,
      fontWeight: 900,
      color: theme.palette.text.navActive,
      padding: '10px 8px',
      marginBottom: theme.spacing(0.5)
    }
  }
}))

const StyledMenuItem = withStyles((theme) => ({
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

export default function ExportMenuComponent({
  showNotification,
  columns,
  fileName,
  apiurl,
  searchCriteria
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)
  const { fetchRecords } = useServices()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const performExport = (format) => () => {
    try {
      console.log('Export format :' + format)
      console.log('searchCriteria', searchCriteria)
      fetchRecords.execute(
        apiurl,
        {
          customData: searchCriteria
        },
        (data) => {
          console.log('data: ' + JSON.stringify(data))
          format === 'pdf'
            ? ExportToPdf(columns, data, fileName)
            : ExportToExcel(columns, data, fileName)
          showSuccessNotification('Successfully export data to ' + format)
        },
        (error) => {
          showErrorNotification(error)
        }
      )
    } catch (err) {
      showErrorNotification(err.message)
    }

    setAnchorEl(null)
  }

  function ExportToPdf(pdfColumns, exportData, pdfFileName) {
    let data = exportData.map((row) => {
      delete row['baseFilterDTO']
      delete row['errorMessages']
      delete row['customData']
      return row
    })
    console.log('data: ' + JSON.stringify(data))
    ExportPdf(pdfColumns, data, pdfFileName, 12)
  }

  function ExportToExcel(excelColumns, exportData, excelFileName) {
    const excelWorkbook = new ExcelJS.Workbook()
    const worksheet = excelWorkbook.addWorksheet(excelFileName)

    worksheet.columns = excelColumns.map((col) => ({
      header: col.title,
      key: col.field
    }))
    exportData.forEach((row) => {
      worksheet.addRow(row)
    })

    worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        if (rowNumber <= 1) {
          row.height = 20
          cell.font = { bold: true }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'E6E6FA' }
          }
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      })
    })

    excelWorkbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${fileName}.xlsx`
      )
    })
  }

  return (
    <>
      <Tooltip title='Export'>
        <Box style={{ height: '100%', width: '100%' }}>
          <Card>
            <IconButton
              onClick={handleClick}
              style={{
                borderRadius: '8px'
              }}
            >
              <FontAwesomeIcon icon={faFileExport} />
            </IconButton>
          </Card>
        </Box>
      </Tooltip>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        className={clsx(toolbarClasses.menu)}
      >
        <MenuItem
          disabled={true}
          style={{ backgroundColor: '#f5f5f5', color: '#212121', opacity: 1 }}
        >
          <Typography variant='subtitle2' style={{ fontSize: 'small' }}>
            <b>Export all as</b>
          </Typography>
        </MenuItem>
        <MenuItem onClick={performExport('pdf')}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faFilePdf} />
          </ListItemIcon>
          <Typography variant='inherit' style={{ fontSize: 'small' }}>
            <b>PDF</b>
          </Typography>
        </MenuItem>

        <MenuItem onClick={performExport('excel')}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faFileExcel} />
          </ListItemIcon>
          <Typography variant='inherit' style={{ fontSize: 'small' }}>
            <b>Excel</b>
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
